<template>
    <v-container>
        <div
            style="display: flex; flex-flow: column wrap"
        >
            <div
                v-if="!loading && !competitionEnded"
                class="d-flex flex-column align-center text-gray-600 mt-6"
            >
                <span class="text-h5 text-center">
                    {{ $t('awards.isLockedTitle') }}
                </span>

                <span class="text-subtitle-2">
                    {{ $t('awards.isLockedDesc') }}
                </span>

                <span
                    v-if="getTimeLeft"
                    class="text-h5 text-center mt-6"
                >
                    {{ $t('awards.timeLeft') }}
                </span>

                <span
                    v-if="getTimeLeft"
                    class="text-h2 text-center mt-2"
                    style="color: #46d5ab"
                    v-html="getTimeLeft"
                />
            </div>
            <div v-else-if="!loading">
                <v-card>
                    <v-card-title class="justify-center">
                        <span class="text-h5 text-center"> {{ $t('awards.titleWinner') }}</span>
                    </v-card-title>

                    <router-view />
                </v-card>
            </div>
        </div>
    </v-container>
</template>

<script>
import axios from '@/plugins/axios'
import { mapGetters } from 'vuex'

export default {
    name: 'Awards',
    data: function () {
        return {
            dateNow: Date.now(),
            // dateEnd: new Date("2021-09-30 23:59:59"),
            loading: true,
            competitionEnded: false,
            refreshIntervalId: null
        }
    },
    computed: {
        ...mapGetters('cache', ['getDateCloseCompetition']),
        getTimeLeft() {
            if (!this.getDateCloseCompetition || this.getDateCloseCompetition < this.dateNow) return undefined;
            return this.dateHelper.showDiffForHumans(this.dateNow, this.getDateCloseCompetition, false);
        }
    },
    async created() {
        this.fetchIsOpenCompetition();
    },
    mounted() {
        this.$store.dispatch('cache/loadDateCloseCompetition');

        var self = this;
        this.refreshIntervalId = setInterval(function () {
            // console.log('setInterval ', self.refreshIntervalId);

            if (self.getDateCloseCompetition && self.dateNow > self.getDateCloseCompetition &&
                !self.competitionEnded && !self.loading && Math.trunc(self.dateNow / 1000) % 3 === 0) {
                self.fetchIsOpenCompetition();
            }

            if (self.competitionEnded) {
                clearInterval(self.refreshIntervalId);
            }

            self.dateNow = Date.now();
        }, 1000);
    },
    beforeDestroy() {
        if (this.refreshIntervalId) {
            clearInterval(this.refreshIntervalId);
        }
    },
    methods: {
        async fetchIsOpenCompetition() {
            this.loading = true;
            this.$store.dispatch('loading/start');
            try {
                const isOpenCompetition = await axios
                    .get('isOpenCompetition');

                if (isOpenCompetition.status === 200) {
                    // console.log(isOpenCompetition.data.isOpenCompetition)
                    this.competitionEnded = !isOpenCompetition.data.isOpenCompetition
                }
            } catch (e) {

            }

            this.loading = false;
            this.$store.dispatch('loading/end');
        }
    }
}
</script>

<style scoped>

@media (min-width: 600px) {

  .areas{
    margin-left: 4rem;
  }
}
</style>
